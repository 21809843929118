import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import firebase from "../firebase";
import InterStyled from "../styles/InterStyled";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Alert, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";

// Columns of the output table
const columns = [
  {
    field: "month",
    headerName: "Month",
    flex: 2,
  },
  {
    field: "shop",
    headerName: "Shop",
    flex: 2,
  },
  {
    field: "orders_total",
    headerName: "Orders",
    flex: 1,
  },
  {
    field: "orders_total_avo",
    headerName: "Avocadostore Orders",
    flex: 1,
  },
  {
    field: "returns_total",
    headerName: "Returns",
    flex: 1,
  },
  {
    field: "returns_total_avo",
    headerName: "Avocadostore Returns",
    flex: 1,
  },

  {
    field: "return_labels",
    headerName: "ReturnLabels",
    flex: 1,
  },
  {
    field: "return_no_labels",
    headerName: "NoLabels",
    flex: 1,
  },
  {
    field: "orders_keepoala",
    headerName: "Keepoala Orders",
    flex: 1,
  },
  {
    field: "greater_30",
    headerName: "> limit",
    flex: 1,
  },
];

/**
 * Monthly reporting as mentioned in
 *
 * https://gitlab.com/keepoala/react-backend/-/issues/37
 *
 * Creates a table to get monthly reports for all
 * shops
 *
 * @returns
 */
export default function MonthlyReporting() {
  const db = firebase.firestore();

  /**
   * States
   */
  const [loading, setLoading] = useState(true);
  const [shops, setShops] = useState([]);
  const [shops_selected, setShopsSelected] = useState([]);
  const [months, setMonths] = useState([]);
  const [aggregate, setAggregate] = useState(false);
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(30);
  const [running, setRunning] = useState(false);
  const [shops_finished, setShopsFinished] = useState(0);
  const [error, setError] = useState("");

  // Create an array of all month to possibly report
  const possible_months = [];
  const current_year = new Date().getFullYear();
  const current_month = new Date().getMonth();
  for (let year = 2021; year <= current_year; year++) {
    for (const month of ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]) {
      if (year === current_year && parseFloat(month) > current_month) {
        // do nothing
      } else {
        possible_months.push(year.toString() + "-" + month);
      }
    }
  }

  // On Load, load cooperation shops!
  useEffect(() => {
    if (shops.length === 0) {
      db.collection("Shops")
        .where("cooperation", "==", true)
        .get()
        .then((shop_docs) => {
          const shop_list = [];
          shop_docs.docs.forEach((shop_doc) => {
            const shop_data = shop_doc.data();

            shop_list.push({
              name: shop_data.name,
              pretty: shop_data.pretty,
            });
          });
          setShops(shop_list);
          setLoading(false);
        });
    }
  }, [db, shops.length]);

  useEffect(() => {
    if (shops_finished === shops_selected.length) {
      setRunning(false);
    }
  }, [shops_finished, shops_selected]);

  /**
   * Function to create the final table with
   *
   * - orders
   * - returnLabels
   * - orders with keepoalas
   * - orders above limit
   *
   * for each shop and month
   */
  const handleSubmit = async () => {
    console.log("START");
    if (!running) {
      setRows([]);
      setRunning(true);
      setShopsFinished(0);

      // Download the data for the shop
      shops_selected.forEach(async (shop_name) => {
        const submission_object = {
          shop: shop_name,
          months: months,
          aggregate: aggregate,
          limit: limit,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-requested-with": "application/json",
            shop: shop_name,
            Signature:
              "sha256=" +
              // Base64.stringify(hmacSHA256(JSON.stringify(submission_object), "XXX")),
              Base64.stringify(hmacSHA256(JSON.stringify(submission_object), process.env.REACT_APP_CURRENT_SERVER_KEY)),
          },
          body: JSON.stringify(submission_object),
        };

        fetch(`${process.env.REACT_APP_REST_API_URL}/reporting`, requestOptions).then((res) => {
          // fetch(`https://europe-west1-keepoalatesting.cloudfunctions.net/reporting`, requestOptions).then((res) => {
          if (res.status === 200) {
            res.json().then((data) => {
              setRows((value) => [...value, ...data]);
              setShopsFinished(shops_finished + 1);
              setError("");
            });
          } else {
            res.text().then((val) => {
              setError(val);
              setRunning(false);
            });
          }
        });
      });
    }
  };

  // Handle change in shops selected
  const handleShopListChange = (event) => {
    const {
      target: { value },
    } = event;
    setShopsSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // handle changes in months selected
  const handleMonthListChange = (event) => {
    const {
      target: { value },
    } = event;
    setMonths(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // If loading shop data show loading
  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <CircularProgress size={100} thickness={3} />
      </div>
    );
  }

  // View
  return (
    <Container maxWidth={false}>
      {error !== "" && <Alert severity="error">{error}</Alert>}
      {/* Form to select the Data */}
      <div
        style={{
          maxWidth: "600px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}>
        <FormControl sx={{ m: 1, width: 600 }}>
          <InputLabel id="demo-multiple-checkbox-label">Shops</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={shops_selected}
            onChange={handleShopListChange}
            input={<OutlinedInput label="Shops" />}
            renderValue={(selected) => selected.join(", ")}>
            {shops.map((shop) => (
              <MenuItem key={shop.name} value={shop.name}>
                <Checkbox checked={shops_selected.indexOf(shop.name) > -1} />
                <ListItemText primary={shop.pretty} secondary={shop.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 600 }}>
          <InterStyled interStyle="P3">Please do not forget to check the ID of PETIT COCHON</InterStyled>
        </FormControl>

        <FormControl sx={{ m: 1, width: 600 }}>
          <InputLabel id="demo-multiple-checkbox-label-months">Months</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label-months"
            id="demo-multiple-checkbox"
            multiple
            value={months}
            onChange={handleMonthListChange}
            input={<OutlinedInput label="Shops" />}
            renderValue={(selected) => selected.join(", ")}>
            {possible_months
              .sort()
              .reverse()
              .map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={months.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: "100%" }}>
          <TextField
            label={"Pay Limit"}
            id="limit"
            onChange={(event) => setLimit(event.target.value)}
            type={"number"}
            value={limit}></TextField>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox id="checbox-aggregate" onChange={(event) => setAggregate(event.target.checked)}></Checkbox>
          }
          label="Aggregate selected months?"
          labelPlacement="end"
          sx={{ m: 1 }}
        />

        <FormControl sx={{ m: 1, marginBottom: "20px" }}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            mb={2}
            id="submit"
            value="Submit"
            disabled={running}
            onClick={handleSubmit}>
            {running && <CircularProgress color="secondary" size={20} thickness={2} />}
            Let's go
          </Button>
        </FormControl>
      </div>

      {/* Table output with download functionality */}
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20, 100, 200]}
        checkboxSelection
        autoHeight={true}
        slots={{
          toolbar: GridToolbar,
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          },
        }}
      />
    </Container>
  );
}
