import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import firebase from "../firebase";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import CardContent from "@mui/material/CardContent";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const Container = styled.div`
  min-height: 93vh;
  background-color: #f5f5f5;
  padding: 40px;
  display: flex;
  justify-content: center;
`;

const ImageUploaderContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

function CreateChallenge() {
  const db = firebase.firestore();

  /**
   * State Variables
   */

  // UI Controlling Variables
  const [general_error, setGeneralError] = useState("");
  const [photo_image_src, setPhotoImageSrc] = useState();
  const [activeStatusFunction, setActiveStatusFunction] = useState(
    "challengeKeepOrders"
  );
  const [shops, setShops] = useState([]);
  const [active_shops, setActiveShops] = useState([]);
  const [status_parameters, setStatusParameters] = useState({});
  const [confirmation_dialog_open, setConfirmationDialogOpen] = useState(false);
  const [challenge_object, setChallengeObject] = useState({});

  // Error Controlling variables
  const [challenge_id_error, setChallengeIDError] = useState("");
  const [form_submitable, setFormSubmitable] = useState(true);
  const [challenge_start_date, setChallengeEndDate] = useState(
    new Date(Date.now())
  );
  const [challenge_end_date, setDateRangeEnd] = useState(new Date(Date.now()));
  const [registration_date_min, setRegistrationDateMin] = useState(
    new Date("2021-01-01")
  );
  const [registration_date_max, setRegistrationDateMax] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  );

  /**
   * Validation Functions
   */

  const validateChallengeID = (id) => {
    const valid = /^[A-z0-9]+$/.test(id);
    if (valid) {
      setChallengeIDError("");
      return true;
    } else if (!valid && id === "") {
      setChallengeIDError("Required");
      return false;
    } else {
      setChallengeIDError("Allowed Values are [A-z0-9] only");
      return false;
    }
  };

  const validateStatusParametersShops = () => {
    if (activeStatusFunction === "challengeKeepOrdersStore") {
      const valid =
        status_parameters.shops !== undefined &&
        status_parameters.shops.length > 0;
      if (valid) {
        setGeneralError("");
        return true;
      } else {
        setGeneralError("Please Select at least one Shop");
        return false;
      }
    }
    return true;
  };

  const validatePhoto = (info) => {
    if (info) {
      console.log(info);
      if (!info.type.match(/^(image\/jpeg|image\/png|image\/webp)$/)) {
        setGeneralError("Photo can only be of type jpeg, jpg, png or webp");
        return false;
      } else if (info.size > 100000) {
        setGeneralError("Maximum Photo size is 100kb");
      } else if (info.width != info.height) {
        setGeneralError("Photo needs to be square");
      } else {
        setGeneralError("");
        return true;
      }
    } else {
      setGeneralError("Photo is Required, Please upload one");
      return false;
    }
  };

  const handleStatusFuncChange = (event) => {
    setActiveStatusFunction(event.target.value);
    setStatusParameters({});
  };

  const handleShopsChange = (event) => {
    setActiveShops(event.target.value);
    setStatusParameters({ ...status_parameters, shops: event.target.value });
  };

  // Fetching shops
  const fetchShopsData = async () => {
    const all_shops = [];
    await db
      .collection("Shops")
      .where("cooperation", "==", true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const shop = doc.data();
          Object.assign(shop, { id: doc.id });
          all_shops.push(shop);
        });
      })
      .catch((err) => {
        console.error("error in fetching shops", err);
        toast.error("Error in fetching data!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      });

    // if there were no shops fetched yet
    if (all_shops.length === 0) {
      toast.error("Error in fetching shops data!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
      return;
    }
    setShops(all_shops);
  };

  // setting all orders at the initial page render
  useEffect(async () => {
    let mounted = true;
    if (mounted) {
      await fetchShopsData();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleDialogClose = () => {
    setConfirmationDialogOpen(false);

    // Enable submitting the form again
    setFormSubmitable(true);
  };

  const handleCreationConfirmed = () => {
    createChallenge();

    // Enable submitting the form again
    setFormSubmitable(true);
  };

  const submitCreateChallenge = async (event) => {
    event.preventDefault();

    // Return if another submit is in place
    if (!form_submitable) return;

    // Stop submitting forms until finished with this submition
    setFormSubmitable(false);

    // Validation
    if (!validateChallengeID(event.target.challenge_id.value)) {
      setFormSubmitable(true);
      return;
    }
    if (!validatePhoto(photo_image_src)) {
      setFormSubmitable(true);
      return;
    }
    if (!validateStatusParametersShops()) {
      setFormSubmitable(true);
      return;
    }

    console.log("Form Validation Passed");

    // Create Challenge object from the data
    const photo = await toBase64(event.target.photo.files[0]);
    setChallengeObject({
      details: {
        de: event.target.details_de.value,
        en: event.target.details_en.value,
      },
      start_date: parseInt(challenge_start_date.getTime() / 1000),
      end_date: parseInt(challenge_end_date.getTime() / 1000),
      icon: photo,
      id: event.target.challenge_id.value,
      keepoalas: parseInt(event.target.keepoalas.value),
      participants: [],
      participation_requirements: {
        registration_date_min: parseInt(registration_date_min.getTime() / 1000),
        registration_date_max: parseInt(registration_date_max.getTime() / 1000),
      },
      status: 0,
      status_string: "0/0",
      status_function: activeStatusFunction,
      status_parameters: status_parameters,
      text: {
        de: event.target.title_de.value,
        en: event.target.title_en.value,
      },
      top10: [],
      user_limit: parseInt(event.target.keepoalas.value),
    });
    setConfirmationDialogOpen(true);
  };

  const createChallenge = () => {
    db.collection("Challenges")
      .doc(challenge_object.id)
      .set(challenge_object)
      .then(() => {
        setConfirmationDialogOpen(false);
        toast.success("Uploaded challenge");
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Failed to Create Challenge, Check the console for the error details",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          }
        );
      });
  };

  // Helper Functions
  const showImageInfo = (event, setSrcInfo) => {
    const file = event.target.files[0];
    if (file) {
      const type = file.type;
      const reader = new FileReader();

      reader.onloadend = function () {
        const img = new Image();
        img.src = reader.result; // Base64-encoded image
        img.onload = function () {
          setSrcInfo({
            src: reader.result,  // Use Base64 instead of objectUrl
            name: file.name,
            width: img.width,
            height: img.height,
            type,
            file,
            size: (file.size / (1024 * 1024))  // Convert size to MB
          });
        };
      };

      reader.readAsDataURL(file);  // Convert file to Base64 data URL
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <Container>
      <Box sx={{ width: 800 }}>
        <Card variant="outlined" sx={{ padding: 3 }}>
          <Typography variant="h5" component="div" sx={{ textAlign: "center" }}>
            Create Challenge
          </Typography>

          <form style={{ marginTop: "15px" }} onSubmit={submitCreateChallenge}>
            {/* Main Required */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  padding: 15,
                }}
              >
                <DesktopDatePicker
                  label="Start Date"
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={challenge_start_date}
                  onChange={(value) => {
                    setChallengeEndDate(value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                  label="End Date"
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={challenge_end_date}
                  onChange={(value) => {
                    setDateRangeEnd(value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
            <TextField
              label="Title DE"
              name="title_de"
              required
              variant="filled"
              fullWidth
              inputProps={{ maxLength: 25 }}
              sx={{ marginBottom: 3 }}
            />
            <TextField
              label="Title EN"
              name="title_en"
              required
              variant="filled"
              fullWidth
              inputProps={{ maxLength: 25 }}
              sx={{ marginBottom: 3 }}
            />
            <TextField
              label="Details DE"
              name="details_de"
              required
              variant="filled"
              fullWidth
              inputProps={{ maxLength: 150 }}
              sx={{ marginBottom: 3 }}
              multiline
              minRows={3}
            />
            <TextField
              label="Details EN"
              name="details_en"
              required
              variant="filled"
              fullWidth
              inputProps={{ maxLength: 150 }}
              sx={{ marginBottom: 3 }}
              multiline
              minRows={3}
            />
            <ImageUploaderContainer>
              <div>
                <Typography component="div">Photo</Typography>

                <Button
                  variant="outlined"
                  component="label"
                  sx={{ margin: "10px" }}
                >
                  Upload
                  <input
                    label="Photo"
                    name="photo"
                    hidden
                    accept="image/*"
                    type="file"
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    onChange={(event) => showImageInfo(event, setPhotoImageSrc)}
                  />
                </Button>
              </div>
              {photo_image_src && (
                <div>
                  <Card sx={{ marginLeft: 10 }}>
                    <img
                      width="400"
                      src={photo_image_src.src}
                      alt="challenge"
                    />
                    <CardContent>
                      <Typography component="div">
                        <span
                          style={{ fontWeight: "bold", marginLeft: "10px" }}
                        >
                          Width:
                        </span>{" "}
                        {photo_image_src.width}
                        <span
                          style={{ fontWeight: "bold", marginLeft: "10px" }}
                        >
                          Height:
                        </span>{" "}
                        {photo_image_src.height}
                        <span
                          style={{ fontWeight: "bold", marginLeft: "10px" }}
                        >
                          Type:
                        </span>{" "}
                        {photo_image_src.type}
                        <span
                          style={{ fontWeight: "bold", marginLeft: "10px" }}
                        >
                          Size:
                        </span>{" "}
                        {parseInt(photo_image_src.size / 1000)}
                        {"kb"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              )}
            </ImageUploaderContainer>

            <TextField
              label="Challenge ID"
              name="challenge_id"
              required
              variant="filled"
              onChange={(event) => validateChallengeID(event.target.value)}
              fullWidth
              sx={{ marginBottom: 3 }}
              error={challenge_id_error === "" ? false : true}
              helperText={challenge_id_error}
            />
            <TextField
              label="Keepoalas"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              name="keepoalas"
              required
              variant="filled"
              fullWidth
              sx={{ marginBottom: 3 }}
            />
            <FormControl fullWidth>
              <InputLabel id="status-label">Status Function</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                value={activeStatusFunction}
                label="Status Function"
                onChange={handleStatusFuncChange}
                style={{ padding: 5, marginBottom: 15 }}
              >
                <MenuItem value={"challengeKeepOrders"}>
                  Challenge Keep Orders
                </MenuItem>
                <MenuItem value={"challengeProductQuestionsOrders"}>
                  Challenge Product Questions Orders
                </MenuItem>
                <MenuItem value={"challengeAnnoyOrders"}>
                  Challenge Annoy Orders
                </MenuItem>
                <MenuItem value={"challengeKeepOrdersStore"}>
                  Challenge Keep Orders Store
                </MenuItem>
                <MenuItem value={"returnRateChallenge"}>
                  Return Rate Challenge
                </MenuItem>
                <MenuItem value={"carbonChallenge"}>Carbon Challenge</MenuItem>
                <MenuItem value={"challengeNewUser"}>
                  Challenge New User{" "}
                </MenuItem>
                <MenuItem value={"challengeScan"}>Challenge Scan</MenuItem>
                <MenuItem value={"challengePartnerVoucher"}>
                  Challenge Partner Voucher
                </MenuItem>
                <MenuItem value={"shareChallenge"}>Share Challenge</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <FormLabel id="status-parameters" sx={{ marginBottom: 1 }}>
                Status Parameters
              </FormLabel>
              {activeStatusFunction === "challengePartnerVoucher" ? (
                <TextField
                  label="Partners"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  name="partners"
                  required
                  variant="filled"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onChange={(event) => {
                    setStatusParameters({
                      partners: parseInt(event.target.value),
                    });
                  }}
                />
              ) : activeStatusFunction === "carbonChallenge" ? (
                <TextField
                  label="Value"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  name="value"
                  required
                  variant="filled"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onChange={(event) => {
                    setStatusParameters({
                      value: parseInt(event.target.value),
                    });
                  }}
                />
              ) : activeStatusFunction === "returnRateChallenge" ? (
                <TextField
                  label="Rate"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  name="rate"
                  required
                  variant="filled"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onChange={(event) => {
                    setStatusParameters({
                      rate: parseInt(event.target.value),
                    });
                  }}
                />
              ) : activeStatusFunction === "challengeKeepOrdersStore" ? (
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="shops-label">Shops</InputLabel>
                    <Select
                      labelId="shops-label"
                      id="shops"
                      value={active_shops}
                      label="Shops"
                      multiple
                      onChange={handleShopsChange}
                      renderValue={(selected) => selected.join(", ")}
                      style={{ marginBottom: 15 }}
                    >
                      {shops.map((shop) => {
                        return (
                          <MenuItem key={shop.id} value={shop.id}>
                            <Checkbox
                              checked={active_shops.indexOf(shop.id) > -1}
                            />
                            <ListItemText
                              primary={
                                shop.pretty !== "" ? shop.pretty : shop.name
                              }
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Count"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    name="orders_count"
                    required
                    variant="filled"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    onChange={(event) => {
                      setStatusParameters({
                        ...status_parameters,
                        count: parseInt(event.target.value),
                      });
                    }}
                  />
                </div>
              ) : activeStatusFunction === "challengeAnnoyOrders" ||
                activeStatusFunction === "challengeProductQuestionsOrders" ||
                activeStatusFunction === "challengeKeepOrders" ? (
                <TextField
                  label="Count"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  name="orders_count"
                  required
                  variant="filled"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onChange={(event) => {
                    setStatusParameters({
                      count: parseInt(event.target.value),
                    });
                  }}
                />
              ) : (
                <div style={{ height: 10 }} />
              )}
            </FormControl>
            <FormControl fullWidth>
              <FormLabel id="participation-requirements">
                Participation Requirements
              </FormLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    padding: 15,
                  }}
                >
                  <DesktopDatePicker
                    label="Registration Date Min"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={registration_date_min}
                    onChange={(value) => {
                      setRegistrationDateMin(value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DesktopDatePicker
                    label="Registration Date Max"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={registration_date_max}
                    onChange={(value) => {
                      setRegistrationDateMax(value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </LocalizationProvider>
            </FormControl>
            <TextField
              label="User Limit"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              name="user_limit"
              required
              variant="filled"
              fullWidth
              sx={{ marginBottom: 3 }}
            />

            <div style={{ textAlign: "center", marginTop: "40px" }}>
              {/* Errors */}
              {general_error && <Alert severity="error">{general_error}</Alert>}

              {/* Create Button */}
              <Button
                disabled={!form_submitable}
                style={{ marginTop: "20px" }}
                variant="outlined"
                type="submit"
              >
                Create Challenge
              </Button>
            </div>
          </form>
        </Card>
        <Dialog
          open={confirmation_dialog_open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Create a Challenge with these details?"}
          </DialogTitle>

          <div>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <b>Details EN: </b> {challenge_object.details?.en} <br />
                <b>Details DE: </b> {challenge_object.details?.de} <br />
                <b>Start Date: </b> {challenge_object.start_date} <br />
                <b>End Date: </b> {challenge_object.end_date} <br />
                <b>Icon: </b> {challenge_object.icon} <br />
                <b>ID: </b> {challenge_object.id} <br />
                <b>Keepoalas: </b> {challenge_object.keepoalas} <br />
                <b>Participants: </b>
                {JSON.stringify(challenge_object.participants)} <br />
                <b>Participation Requirements: </b>
                {JSON.stringify(challenge_object.participation_requirements)}
                <br />
                <b>Status: </b> {challenge_object.status} <br />
                <b>Status String: </b> {challenge_object.status_string} <br />
                <b>Status Function: </b> {challenge_object.status_function}
                <br />
                <b>Status Parameters: </b>
                {JSON.stringify(challenge_object.status_parameters)} <br />
                <b>Title DE: </b> {challenge_object.text?.de} <br />
                <b>Title EN: </b> {challenge_object.text?.en} <br />
                <b>User Limit: </b> {challenge_object.user_limit} <br />
                <b>Top 10: </b> {JSON.stringify(challenge_object.top10)} <br />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Disagree</Button>
              <Button onClick={handleCreationConfirmed} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Box>
    </Container>
  );
}

export default CreateChallenge;
