import React, { useState, useEffect, createRef } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import firebase from '../firebase';
import { toast } from 'react-toastify';
import InterStyled from '../styles/InterStyled';
import SelectInput from '../components/SelectInput';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '../styles/Button';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ImageUploader from '../components/ImageUploader';
import Preview from '../components/Preview';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

const Container = styled.div`
  min-height: 90vh;
  padding: 40px;
  padding-top: 60px;
  display: flex;
  justify-content: center;
`;

const ColumnContainer = styled.div`
  width: 400px;
`;

const GreenTextField = styled(TextField)`
  & label.Mui-focused {
    color: #2f8481;
  }
  & .MuiInputBase-colorPrimary::after {
    border-bottom: 1px solid #2f8481;
  }
`;

function VoucherPartner() {
  /**
   * State Variables
   */

  const db = firebase.firestore();
  const formRef = createRef();
  const [shopNames, setShopNames] = useState([]);
  const [filteredShopNames, setFilteredShopNames] = useState([]);
  const [tagsAndProducts, setTagsAndProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [previewData, setPreviewData] = useState();
  const [disableCreate, setDisableCreate] = useState(false);

  /**
   * Form Inputs Variables
   */
  const [selectedShopNames, setSelectedShopNames] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [logoImages, setLogoImages] = useState([]);
  const [featureImages, setFeatureImages] = useState([]);

  /**
   * Error Variables
   */
  const [partnerIDError, setPartnerIDError] = useState('');
  const [partnerDescENError, setPartnerDescENError] = useState('');
  const [partnerDescDEError, setPartnerDescDEError] = useState('');
  const [logoImagesError, setLogoImagesError] = useState('');
  const [featureImagesError, setFeatureImagesError] = useState('');
  const [generalError, setGeneralError] = useState('');

  // Get data on mount
  useEffect(() => {
    db.collection('Shops')
      .get()
      .then((querySnapshot) => {
        let tempShopNames = [];
        const tempFilteredShopNames = [];
        querySnapshot.forEach((doc) => {
          const brandname =
            doc.data().pretty !== undefined && doc.data().pretty !== ''
              ? doc.data().pretty
              : doc.data().name;
          tempShopNames.push(brandname);
          tempFilteredShopNames.push(doc.data().name);
        });
        tempShopNames = new Set(tempShopNames);
        tempShopNames.delete('');
        setShopNames([...tempShopNames]);
        setFilteredShopNames([...tempFilteredShopNames]);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });

    db.collection('Vouchers')
      .get()
      .then((querySnapshot) => {
        let tempTags = [];
        let tempCategories = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().tags && doc.data().tags.length) {
            tempTags.push(...doc.data().tags);
          }
          if (doc.data().category) {
            tempCategories.push(doc.data().category);
          }
        });
        tempTags = new Set(tempTags);
        tempTags.delete('');
        tempCategories = new Set(tempCategories);
        tempCategories.delete('');
        setTagsAndProducts([...tempTags]);
        setCategories([...tempCategories]);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  }, [db]);

  /**
   * Validatation and Setting functions
   */

  const validatePartnerID = (id) => {
    const valid = /^[A-z0-9.\-:/]+$/.test(id);
    if (valid || id === '') {
      setPartnerIDError('');
      return true;
    } else {
      setPartnerIDError('Allowed Values are [A-z0-9.-:/] only');
      return false;
    }
  };

  const validatePartnerDesc = (value, lang) => {
    const wordNumber = value
      .trim()
      .split(' ')
      .filter((el) => (el !== '' ? true : false)).length;
    if (lang === 'en') {
      if (value === '') {
        setPartnerDescENError('');
        return false;
      } else if (wordNumber < 100) {
        setPartnerDescENError(
          'Please Describe the Partner with at least 100 words. Current Count : ' +
            wordNumber,
        );
        return false;
      } else {
        setPartnerDescENError('');
        return true;
      }
    } else {
      if (value === '') {
        setPartnerDescDEError('');
        return false;
      } else if (wordNumber < 100) {
        setPartnerDescDEError(
          'Please Describe the Partner with at least 100 words. Current Count : ' +
            wordNumber,
        );
        return false;
      } else {
        setPartnerDescDEError('');
        return true;
      }
    }
  };

  const validatePartnerLogo = () => {
    if (logoImages.length === 0) {
      setLogoImagesError('One Image is Required');
      setGeneralError('Partner Logo: One Image is Required');
      return false;
    } else if (logoImages.length > 1) {
      setLogoImagesError(
        'Only One Image is Required, Please remove the others',
      );
      setGeneralError(
        'Partner Logo: Only One Image is Required, Please remove the others',
      );
      return false;
    } else {
      setLogoImagesError('');
      setGeneralError('');
      return true;
    }
  };

  const validateFeatureImages = () => {
    if (featureImages.length < 1 || featureImages.length > 10) {
      setFeatureImagesError('1 - 10 Images are Required');
      setGeneralError('Feature Images: 1 - 10 Images are Required');
      return false;
    } else {
      setFeatureImagesError('');
      setGeneralError('');
      return true;
    }
  };

  const validateImageSize = () => {
    let totalSize = 0;
    featureImages.forEach((image) => (totalSize += image.size));
    logoImages.forEach((image) => (totalSize += image.size));
    console.log('Images Size', totalSize);
    if (totalSize > 9.5) {
      setGeneralError(
        'Total Image Size Cannot Exceed 9.5 MB, Current Size is ' +
          totalSize.toFixed(3) +
          ' MB',
      );
      return false;
    } else {
      setGeneralError('');
      return true;
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // Validate and Show the preview
  const validateInputs = (form) => {
    // Validation
    if (!validatePartnerID(form.partnerid.value)) {
      return false;
    }
    if (!validatePartnerLogo()) {
      return false;
    }
    if (!validateFeatureImages()) {
      return false;
    }
    if (!validateImageSize()) {
      return false;
    }
    if (!validatePartnerDesc(form.partnerdescriptionde.value, 'de')) {
      return false;
    }
    if (!validatePartnerDesc(form.partnerdescriptionen.value, 'en')) {
      return false;
    }

    console.log('Validation Passed');
    return true;
  };

  const showPreviewData = (event) => {
    event.preventDefault();

    // Validation
    if (!validateInputs(event.target)) return;

    // Object Creation
    const formData = {
      partnername: event.target.partnername.value,
      partnerid: event.target.partnerid.value,
      logo: logoImages,
      feature_images: featureImages,
      partnerdescriptionen: event.target.partnerdescriptionen.value,
      partnerdescriptionde: event.target.partnerdescriptionde.value,
      brands: selectedShopNames,
      productstags: selectedTags,
      category: selectedCategory[0],
      cooperation: event.target.cooperation.checked,
      url: event.target.url.value,
    };

    console.log(formData);
    setPreviewData(formData);
  };

  const createVoucherPartner = async () => {
    // Validation
    if (!formRef.current.reportValidity()) return;
    if (!validateInputs(formRef.current)) return;

    // Disable the create button
    setDisableCreate(true);

    // Preview Object Creation
    const formData = {
      partnername: formRef.current.partnername.value,
      partnerid: formRef.current.partnerid.value,
      logo: logoImages,
      feature_images: featureImages,
      partnerdescriptionen: formRef.current.partnerdescriptionen.value,
      partnerdescriptionde: formRef.current.partnerdescriptionde.value,
      brands: selectedShopNames,
      productstags: selectedTags,
      category: selectedCategory[0],
      cooperation: formRef.current.cooperation.checked,
      url: formRef.current.url.value,
    };

    setPreviewData(formData);

    // Preview Object Creation
    const partnerObject = {
      partnername: formRef.current.partnername.value,
      partnerid: formRef.current.partnerid.value,
      logo: logoImages,
      feature_images: featureImages,
      description_en: formRef.current.partnerdescriptionen.value,
      description_de: formRef.current.partnerdescriptionde.value,
      brands: selectedShopNames,
      tags: selectedTags,
      category: selectedCategory[0],
      cooperation: formRef.current.cooperation.checked,
      url: formRef.current.url.value,
    };

    // Create Document
    partnerObject.logo = await toBase64(partnerObject.logo[0].file);
    const newFeature_images = [];
    for (const image of partnerObject.feature_images) {
      const file = await toBase64(image.file);
      newFeature_images.push(file);
    }
    partnerObject.feature_images = [...newFeature_images];
    console.log('REST BODY:', partnerObject);
    console.log('REST URL:', process.env.REACT_APP_REST_API_URL);

    // Send the API REQUEST
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-requested-with': 'application/json',
        Signature:
          'sha256=' +
          Base64.stringify(
            hmacSHA256(
              JSON.stringify(partnerObject),
              process.env.REACT_APP_CURRENT_SERVER_KEY,
            ),
          ),
      },
      body: JSON.stringify(partnerObject),
    };

    fetch(
      `${process.env.REACT_APP_REST_API_URL}/voucher_partners_creation`,
      requestOptions,
    )
      .then((res) => {
        if (res.status === 200) {
          // Send a notification
          toast.success('Voucher Partner Created Successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          });
        } else {
          toast.error(
            'Failed to Create Voucher Partner, Check the console for the error details',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
            },
          );
        }

        // Renable the create button
        setDisableCreate(false);
      })
      .catch((err) => {
        console.error(err);
        // Send a notification
        toast.error(
          'Failed to Create Voucher Partner, Check the console for the error details',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
          },
        );

        // Renable the create button
        setDisableCreate(false);
      });
  };

  return (
    <Container>
      <Box
        sx={{ width: 900, justifyContent: 'space-between', display: 'flex' }}
      >
        <ColumnContainer>
          <InterStyled interStyle='H2' color='greytext' transform='Uppercase'>
            Voucher Partner Creation
          </InterStyled>

          <form
            style={{ marginTop: '40px' }}
            onSubmit={showPreviewData}
            ref={formRef}
          >
            <GreenTextField
              label='Partner Name'
              name='partnername'
              variant='filled'
              required
              fullWidth
              sx={{ marginBottom: 3 }}
            />

            <Autocomplete
              freeSolo
              id='free-solo-2-demo'
              disableClearable
              options={filteredShopNames}
              onChange={(event) => validatePartnerID(event.target.value)}
              renderInput={(params) => (
                <GreenTextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  label='Partner ID'
                  name='partnerid'
                  variant='filled'
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  required
                  onChange={(event) => validatePartnerID(event.target.value)}
                  error={partnerIDError === '' ? false : true}
                  helperText={partnerIDError}
                />
              )}
            />

            <ImageUploader
              images={logoImages}
              setImages={setLogoImages}
              errorMessage={logoImagesError}
              label='Partner Logo (one image)'
            />
            <ImageUploader
              images={featureImages}
              setImages={setFeatureImages}
              errorMessage={featureImagesError}
              label='Feature Images (up to 10)'
            />

            <GreenTextField
              label='Partner Description DE'
              name='partnerdescriptionde'
              variant='filled'
              multiline
              rows={4}
              fullWidth
              sx={{ marginBottom: 3 }}
              required
              onChange={(event) =>
                validatePartnerDesc(event.target.value, 'de')
              }
              error={partnerDescDEError === '' ? false : true}
              helperText={partnerDescDEError}
            />

            <GreenTextField
              label='Partner Description EN'
              name='partnerdescriptionen'
              variant='filled'
              multiline
              rows={4}
              fullWidth
              sx={{ marginBottom: 3 }}
              required
              onChange={(event) =>
                validatePartnerDesc(event.target.value, 'en')
              }
              error={partnerDescENError === '' ? false : true}
              helperText={partnerDescENError}
            />

            <SelectInput
              multiple={true}
              required
              name='productstags'
              label='Products / Tags'
              data={tagsAndProducts}
              value={selectedTags}
              onChange={setSelectedTags}
            />
            <SelectInput
              multiple={true}
              required
              name='brands'
              label='Brands Sold'
              data={shopNames}
              value={selectedShopNames}
              onChange={setSelectedShopNames}
            />

            <SelectInput
              required
              name='category'
              label='Category'
              data={categories}
              value={selectedCategory}
              onChange={setSelectedCategory}
            />

            <GreenTextField
              label='URL'
              name='url'
              variant='filled'
              required
              fullWidth
              sx={{ marginBottom: 1 }}
            />

            <FormControlLabel
              control={<Checkbox name='cooperation' />}
              label='Cooperation'
              labelPlacement='end'
              sx={{ marginBottom: 1 }}
            />

            {/* Errors */}
            {generalError && (
              <Alert
                severity='error'
                style={{ textAlign: 'center', marginBottom: '10px' }}
              >
                {generalError}
              </Alert>
            )}

            {/* Preview Button */}
            <Button
              fullWidth
              color='primary'
              type='submit'
              style={{ marginBottom: '10px' }}
            >
              Preview
            </Button>
            <Button
              fullWidth
              color='secondary'
              disabled={disableCreate}
              onClick={createVoucherPartner}
            >
              Create
            </Button>
          </form>
        </ColumnContainer>
        <ColumnContainer>
          <InterStyled interStyle='H2' color='greytext' transform='Uppercase'>
            Preview
          </InterStyled>
          <Preview previewData={previewData} />
        </ColumnContainer>
      </Box>
    </Container>
  );
}

export default VoucherPartner;
